import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/aws/aws-banner.jpg";
import ContrastSection from "../../../components/ContrastSection";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Icons
import ExpertiseAWS from "../../../images/service-icons/aws/expertise.svg";
import InnovationAWS from "../../../images/service-icons/aws/innovation.svg";
import CustomisedAWS from "../../../images/service-icons/aws/custom.svg";
import CostAWS from "../../../images/service-icons/aws/cost.svg";
import PartnershipAWS from "../../../images/service-icons/aws/partner.svg";

// Partner logos
import AwsPartnerSTS from "../../../images/partners/aws-partner.png";
import AwsPartnerPS from "../../../images/partners/aws-partnerPS.png";

// Clients
import Pokitpal from "../../../images/services/logo/pokitpal.png";
import GigaClear from "../../../images/services/logo/gigaclear.png";
import Solcast from "../../../images/home-page/logos-png/Solcast.png";

// Casestudy
import downloadFile from "../../../documents/Case-Study-AWS-Solcast.pdf";
import downloadFile2 from "../../../documents/Case-Study-AWS-pokitpal.pdf";
import caseStudyImgOne from "../../../images/services/aws/solocast-casestudy.jpg";
import caseStudyImgTwo from "../../../images/services/aws/pokitpal-casestudy.jpg";
import CasestudyTwo from "../../../components/CasestudyTwo";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial3 from "../../../components/ClientTestimonialGaryPokitpal";
import GaryCobain from "../../../images/Clients/GaryCobain.jpeg";

// Contact
import UserCard from "../../../components/UserCard";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Chamal from "../../../images/services/aws/Chamal.png";

const AWS = () => {
  const servicesList = [
    {
      id: "1",
      title: "Applications Deployment",
      textbold: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      textdetail:
        "We facilitate seamless migration to the AWS Cloud through two key approaches: Cloud Native Restructuring and Container Adoption. With Cloud Native Restructuring, minimal changes to your applications unlock the full potential of AWS cloud services, enhancing scalability and reliability without extensive refactoring. Container Adoption offers a modern, scalable solution for complex applications, reducing management overhead and improving portability through containerisation and orchestration frameworks like Kubernetes. Trust us to optimise your deployment strategy, ensuring agility, scalability, and efficiency in the cloud.",
    },
    {
      id: "2",
      title: "Database Services",
      textbold: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      textdetail:
        "iTelaSoft offers comprehensive solutions for modernising your database infrastructure on the AWS cloud platform. Our expertise enables seamless migration and optimisation, providing the processing power, scalability, and uptime required for your most demanding applications. Through automation and purpose-built databases, we streamline provisioning and management tasks, allowing you to focus on core development activities. Say goodbye to inflexible commercial databases and embrace cloud-optimised, open-source-compatible alternatives for improved performance and cost efficiency. With scalability and high availability, your database remains accessible to your applications, ensuring uninterrupted operation.",
    },
    {
      id: "3",
      title: "DevOps",
      textbold: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      textdetail:
        "We recognise the pivotal role of DevOps in accelerating innovation. Our expertise enables organisations to implement DevOps best practices, leveraging AWS solutions for automated cloud infrastructure management. Through DevOps, we facilitate faster time-to-market and continuous delivery of mission-critical applications, products, and services. By automating resource management and provisioning using Infrastructure as Code (IaC), we enable rapid innovation while ensuring resilience and security. Trust iTelaSoft to align your development and operations teams, fostering collaboration and early issue detection for enhanced application reliability and security.",
    },
    {
      id: "4",
      title: "Cloud Cost Optimisations",
      textbold: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      textdetail:
        "iTelaSoft specialises in helping companies maximise cost efficiency in their AWS usage. By carefully analysing your AWS usage, services, and pricing options, we ensure that you only pay for the resources you truly need. Our proven strategies have helped numerous companies drastically reduce their monthly spend, allowing them to optimise their cloud investments while maintaining performance and scalability.",
    },
    {
      id: "5",
      title: "Managed Cloud Operations",
      textbold: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      textdetail:
        "Achieving operational excellence in the AWS cloud demands a holistic approach. iTelaSoft provides comprehensive support through a dedicated team of engineers, guiding you through every aspect of your cloud journey. From seamless cloud migrations to complete application support and infrastructure build-out, we offer a suite of services tailored to your needs. Benefit from around-the-clock security, robust DevOps practices, expert disaster recovery planning, and proactive cost optimisation strategies. With iTelaSoft, rest assured that your cloud operations are in capable hands, allowing you to focus on driving innovation and growth.",
    },
  ];
  const tabData = [
    {
      label: "Gary Cobain",
      text: "PokitPal",
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
  ];
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile, downloadFile2]);

    const formData = { ...values };
    formData.Message =
      "\nCASE STUDY 1: HOW AN EXPERT PARTNER SAVED SOLCAST 20% ON AWS COSTS\nCASE STUDY 2: HOW A FINTECH COMPANY REDUCED CLOUD SPENDING BY 40% WITH THE RIGHT SUPPORT";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: AMAZON WEB SERVICES";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page aws-service">
      <Seo
        title="Accelerating Innovation and Growth with AWS Solutions"
        description="iTelaSoft is an Australian-owned company providing IT services and
              software product development. We specialise in strategy
              consultation, design, development and management of products. We
              help entrepreneurs transform ground breaking ideas into successful
              solutions."
      />

      <IntroBanner
        title="Accelerating Innovation and Growth with AWS Solutions"
        subtitle="Unlocking the power of AWS for growth and optimisation"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="Professional cloud services delivered by experienced AWS experts."
          para="iTelaSoft provides a range of cloud services to companies across all industries. Our cloud experts have helped organisations on cloud-native restructuring, cloud cost optimisation, DevOps, and many more, to optimise business performance and increase long-term profitability."
        />
      </div>

      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row">
                  <div className="col-md-6 pr-md-5 d-flex justify-content-center align-items-center">
                    <div className="partner-badges">
                      <img
                        className="img-badge mr-1"
                        src={AwsPartnerSTS}
                        alt="PartnerBadge-STS"
                      />
                      <img
                        className="img-badge mr-1"
                        src={AwsPartnerPS}
                        alt="PartnerBadge-PS"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    <p>
                      At iTelaSoft, we take pride in our partnership with Amazon
                      Web Services (AWS), marked by our distinguished AWS
                      Partnership badges. As an AWS Select Tier Partner and
                      Public Sector Partner, we uphold the highest standards of
                      service delivery, ensuring that our clients benefit from
                      the latest advancements in cloud technology. We stand
                      ready to guide organisations through their digital
                      transformation journey, delivering scalable, secure, and
                      cost-effective solutions tailored to their unique needs.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <div className="title title-1">Why Partner with iTelaSoft?</div>
          <div className="sub-title">
            At iTelaSoft, we stand out as your premier choice for AWS expertise.
            Our commitment to client success drives everything we do. With our
            deep knowledge and experience in AWS solutions, we deliver
            innovative, scalable, and cost-effective outcomes tailored to your
            specific requirements. Trust iTelaSoft to be your strategic partner
            in unlocking the full potential of cloud technology for your
            organisation.
          </div>
          <Row className="mt-2">
            <Col>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={ExpertiseAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Expertise in AWS Technologies:</strong>
                  <p>
                    As an AWS Select Tier Partner and Public Sector Partner,
                    iTelaSoft demonstrates a high level of proficiency in AWS
                    technologies. Their team comprises AWS-certified
                    professionals who leverage this expertise to deliver
                    innovative solutions.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={InnovationAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Continuous Innovation:</strong>
                  <p>
                    iTelaSoft keeps abreast of the latest advancements in AWS
                    technologies and continuously explores innovative solutions
                    to address emerging challenges. By staying ahead of the
                    curve, they help clients future-proof their IT
                    infrastructure and stay competitive in the market.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={CustomisedAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Customised Solutions:</strong>
                  <p>
                    iTelaSoft understands that every business has unique
                    requirements. They excel in crafting customised solutions
                    tailored to meet the specific needs and challenges of each
                    client, ensuring optimal performance and efficiency.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={CostAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Cost Optimisation:</strong>
                  <p>
                    iTelaSoft is committed to helping clients optimise their AWS
                    usage and minimise costs. Through careful planning, resource
                    allocation, and implementation of cost-saving strategies,
                    they help clients achieve maximum value from their AWS
                    investments.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={PartnershipAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Collaborative Partnership:</strong>
                  <p>
                    iTelaSoft views clients as strategic partners and
                    collaborates closely with them to achieve shared goals.
                    Facilitates open communication, transparency, and mutual
                    trust, laying the foundation for successful, long-term
                    partnerships.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1">Amazon Web Services(AWS) </div>
              </div>
              <div className="mt-4">
                {servicesList.map((service) => (
                  <div className="wrapper-service" key={service.id}>
                    <div className="service-details">
                      <div className="service-title">{service.title}</div>
                      <div className="service-content">
                        <div className="service-breif pb-1">
                          {service.textbold}
                        </div>
                        <div className="service-description">
                          {service.textdetail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-0">
        <CasestudyTwo
          image1={caseStudyImgOne}
          title1="How an expert partner saved solcast 20% on AWS costs"
          text1="Discover how iTelaSoft's expert guidance paved the way for Solcast to slash AWS costs by 20%, ensuring sustained growth and success in their cloud endeavors."
          download1={null}
          image2={caseStudyImgTwo}
          title2="How a fintech company reduced cloud spending by 40% with the right support"
          text2="Learn how iTelaSoft's expertise enabled a fintech company to reduce cloud spending by 40%, fostering sustained growth and success in their AWS journey."
          download2={null}
        />
      </section>

      <section className="pt-0">
        <div className="section-padding wrap-contrast-section download-portfolio consultation wrap-case-study">
          <Container>
            <div className="contrast-section">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="flex-column">
                        <div className="form-segment pb-3 mx-1">
                          <p>
                            <b>Download the Case Studies Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="email-area col-lg-6 col-md-6 col-sm-6 col-12 pr-1 pl-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Studies
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />
                          <p className="small-text">
                            By downloading these case studies, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
              <div id="who-depend-on-us">
                {" "}
                <div className="title-1 mb-2">Who Depends on Us? </div>
                <div className="section-discription">
                  Discover some of the esteemed organisations iTelaSoft has had
                  the privilege to serve. From startups to enterprise-level
                  companies, our clients trust us to deliver innovative
                  solutions and exceptional service, driving their success in
                  the AWS cloud.
                </div>
                <div className="section-our-partners-logo-set wso2-partners mb-4">
                  <a
                    href="https://www.pokitpal.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Pokitpal} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://solcast.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Solcast} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://gigaclear.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={GigaClear} alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="client-testimonial-section mb-4">
        <Container>
          <div className="tab-header-aws">
            <h3 className="title-1">What our client says</h3>
          </div>
          <TabcmpSet tabData={tabData} tabNum={1} />
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Chamal Fernando"
              >
                <UserCard
                  name="Chamal Fernando"
                  detail="Adviser"
                  image={Chamal}
                  link="https://www.linkedin.com/in/chamal-fernando-3a355755/"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default AWS;
